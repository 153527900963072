import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "fw-bolder m-0" }
const _hoisted_7 = {
  id: "kt_account_profile_details",
  class: "collapse show"
}
const _hoisted_8 = { class: "card-body border-top p-9" }
const _hoisted_9 = { class: "row mb-6" }
const _hoisted_10 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url('/media/avatars/blank.png')"}
}
const _hoisted_13 = {
  key: 1,
  class: "image-input-wrapper w-125px h-125px",
  style: `background-image: url('/media/avatars/blank.png')`
}
const _hoisted_14 = {
  class: "btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow",
  "data-kt-image-input-action": "change",
  "data-bs-toggle": "tooltip",
  title: "Change avatar"
}
const _hoisted_15 = { class: "form-text" }
const _hoisted_16 = { class: "row mb-6" }
const _hoisted_17 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_18 = { class: "col-lg-8" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-lg-12 fv-row" }
const _hoisted_21 = { class: "fv-plugins-message-container" }
const _hoisted_22 = { class: "fv-help-block" }
const _hoisted_23 = { class: "row mb-6" }
const _hoisted_24 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_25 = { class: "col-lg-8" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-lg-12 fv-row" }
const _hoisted_28 = { class: "row mb-6" }
const _hoisted_29 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_30 = { class: "col-lg-8" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-lg-12 fv-row" }
const _hoisted_33 = { class: "row mb-6" }
const _hoisted_34 = { class: "col-lg-4 col-form-label required fw-bold fs-6" }
const _hoisted_35 = { class: "col-lg-8" }
const _hoisted_36 = { class: "row" }
const _hoisted_37 = { class: "col-lg-12 fv-row" }
const _hoisted_38 = { class: "row mb-6" }
const _hoisted_39 = { class: "col-lg-8" }
const _hoisted_40 = { class: "row" }
const _hoisted_41 = { class: "col-lg-12 fv-row" }
const _hoisted_42 = { class: "row mb-6" }
const _hoisted_43 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_44 = { class: "col-lg-8 fv-row" }
const _hoisted_45 = { class: "d-flex align-items-center mt-3" }
const _hoisted_46 = { class: "form-check form-check-inline form-check-solid me-5" }
const _hoisted_47 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_48 = { class: "form-check form-check-inline form-check-solid" }
const _hoisted_49 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_50 = { class: "row mb-0" }
const _hoisted_51 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_52 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_53 = { class: "form-check form-check-solid form-switch fv-row" }
const _hoisted_54 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_55 = {
  type: "reset",
  class: "btn btn-white btn-active-light-primary me-2"
}
const _hoisted_56 = {
  type: "submit",
  id: "kt_account_profile_details_submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_57 = { class: "indicator-label" }
const _hoisted_58 = { class: "indicator-progress" }
const _hoisted_59 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("pages.settings.title")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Form, {
            id: "kt_account_profile_details_form",
            class: "form",
            novalidate: "novalidate",
            onSubmit: _ctx.submitData
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("pages.settings.avatar")), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      (this.$store.getters.currentSettings.clientLogo)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "image-input-wrapper w-125px h-125px",
                            style: _normalizeStyle(`background-image:url('` + this.$store.getters.currentSettings.clientLogo + `')`)
                          }, null, 4))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13)),
                      _createElementVNode("label", _hoisted_14, [
                        _cache[9] || (_cache[9] = _createElementVNode("i", { class: "bi bi-pencil-fill fs-7" }, null, -1)),
                        _createElementVNode("input", {
                          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImage && _ctx.handleImage(...args))),
                          type: "file",
                          name: "avatar",
                          accept: ".png, .jpg, .jpeg, .svg"
                        }, null, 32),
                        _cache[10] || (_cache[10] = _createElementVNode("input", {
                          type: "hidden",
                          name: "avatar_remove"
                        }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("pages.settings.allowedFiles")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("pages.settings.name")), 1),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_Field, {
                          type: "text",
                          name: "clientName",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: "Nom client",
                          modelValue: this.$store.getters.currentSettings.clientName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.$store.getters.currentSettings.clientName) = $event))
                        }, null, 8, ["modelValue"]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_ErrorMessage, { name: "fname" })
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t("pages.settings.signature")), 1),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: "Signature Client",
                          name: "clientSignature",
                          modelValue: this.$store.getters.currentSettings.clientSignature,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.$store.getters.currentSettings.clientSignature) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("pages.dashboard.entities")), 1),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("input", {
                          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (this.handleFile && this.handleFile(...args))),
                          type: "file",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: "Struture organisationnelle",
                          name: "entitiesFile"
                        }, null, 32)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("pages.settings.group")), 1),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("input", {
                          onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (this.handleFileGroup && this.handleFileGroup(...args))),
                          type: "file",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: "Rapport groupe",
                          name: "fileReport"
                        }, null, 32)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-bold fs-6" }, "Participation Groupe", -1)),
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createElementVNode("div", _hoisted_41, [
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                          placeholder: "Participation groupe",
                          name: "groupParticipationLink",
                          modelValue: this.$store.getters.currentSettings.groupParticipationLink,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.$store.getters.currentSettings.groupParticipationLink) = $event))
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t("pages.settings.languages")), 1),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _createElementVNode("label", _hoisted_46, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input",
                          name: "communication[]",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.$store.getters.currentSettings.fr) = $event)),
                          type: "checkbox"
                        }, null, 512), [
                          [_vModelCheckbox, this.$store.getters.currentSettings.fr]
                        ]),
                        _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t("pages.settings.fr")), 1)
                      ]),
                      _createElementVNode("label", _hoisted_48, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input",
                          name: "communication[]",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.$store.getters.currentSettings.en) = $event)),
                          type: "checkbox"
                        }, null, 512), [
                          [_vModelCheckbox, this.$store.getters.currentSettings.en]
                        ]),
                        _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.$t("pages.settings.en")), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t("pages.settings.activePlatform")), 1),
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input w-45px h-30px",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.$store.getters.currentSettings.is_activated) = $event)),
                        type: "checkbox",
                        id: "allowmarketing"
                      }, null, 512), [
                        [_vModelCheckbox, this.$store.getters.currentSettings.is_activated]
                      ]),
                      _cache[12] || (_cache[12] = _createElementVNode("label", {
                        class: "form-check-label",
                        for: "allowmarketing"
                      }, null, -1))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("button", _hoisted_55, _toDisplayString(_ctx.$t("general.cancel")), 1),
                _createElementVNode("button", _hoisted_56, [
                  _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.$t("general.save")), 1),
                  _createElementVNode("span", _hoisted_58, [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
                  ]),
                  _createElementVNode("span", _hoisted_59, [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
                  ])
                ], 512)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ])
      ])
    ])
  ]))
}